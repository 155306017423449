
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiProject from "@/request/apis/api_project";
import * as dayjs from 'dayjs'
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {CheckOutlined} from "@ant-design/icons-vue";
import {IProjectItem} from "@/models/project_model";
import ProjectSet from "@/views/project/components/project-set.vue";

interface IState {
  list: IProjectItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item: IProjectItem | null
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '图标',
    dataIndex: 'tag_image',
    key: 'tag_image',
  },
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '跳转链接',
    dataIndex: 'link',
    key: 'link',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
  },
];

export default defineComponent({
  name: 'ProjectList',
  components: {ProjectSet, ImageView, CheckOutlined},
  setup() {
    const projectSetRef = ref()

    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      searches: {
        title: '', //终端
        status: '', // 是否开启1,0
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize
      },
      visible: false,
      item: null
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否启用pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      StorageUtil.setParams(state.searches)
      state.loading = true
      const res = await ApiProject.getProjectList({...state.searches})
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.total || 0
        state.list = res.data?.data || []
      }
    }

    const setDetailVisible = (flg: boolean, item: IProjectItem | null = null) => {
      state.visible = flg

      if (!item) {
        setTimeout(() => state.item = item, 300)
      } else {
        state.item = item
      }
    }

    const setVisible = (flg: boolean, item: any = null) => {
      projectSetRef.value.setVisible(flg, item)
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = PageUtil.pageNo
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = PageUtil.pageNo
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      projectSetRef,
      handleTableChange,
      dayjs,
      onSearch,
      resetSearch,
      setVisible,
      getList,
      setDetailVisible,
    }
  }
})
