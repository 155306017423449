
import {defineComponent, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import ApiProject from "@/request/apis/api_project";
import EditorComponent from "@/components/editor/Editor.vue";

interface IState {
  loading: boolean,
  visible: boolean
}

// 新增编辑文章
export default defineComponent({
  name: "ProjectSet",
  components: {EditorComponent, UploadComponent},
  emits: ['onOk'],
  setup(props, content) {
    const formRef = ref()
    const editorComponentRef = ref()

    const formState: UnwrapRef<any> = reactive({
      title: "", //广告标题
      link: "", //链接
      status: 1,//0禁用1启用
      image: "", //图片地址
      tag_image: '',//图标地址
      content: '',//富文本
      id: null,
      sort: 0
    })

    // 校验
    const rules = {
      title: [{required: true, message: "请输入文章标题"}],
      link: [{required: true, message: "请输入跳转链接"}],
      image: [{required: true, message: "请上传广告图片"}],
      tag_image: [{required: true, message: "请上传图标"}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let data = {...toRaw(formState)}
            data.sort = Number(data.sort)
            data.content = editorComponentRef.value.getHtmlText()
            if (data.id) {
              delete data.create_time
              delete data.update_time
              onEdit(data)
            } else {
              onAdd(data)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    const onImageChange = (url: any, key) => {
      console.log(url, key)
      formState[key] = url
    }

    const state: IState = reactive({
      loading: false,
      visible: false,
    })

    // 新增
    const onAdd = async (data: any) => {
      state.loading = true
      const res = await ApiProject.projectAdd(data)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          setVisible(false)
          content.emit('onOk')
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (data: any) => {
      state.loading = true
      const res = await ApiProject.projectEdit(data)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          setVisible(false)
          content.emit('onOk')
          return
        }
        message.error(res.msg)
      }
    }

    const setVisible = (flg: boolean, item: any = null) => {
      state.visible = flg
      if (item) {
        for (let key in item) {
          formState[key] = item[key]
        }
      } else {
        formState.title = "" //广告标题
        formState.link = "" //链接
        formState.status = 1//0禁用1启用
        formState.image = "" //图片地址
        formState.tag_image = ''//图标地址
        formState.content = ''//富文本
        formState.id = null
        formState.sort = 0
        editorComponentRef.value.setHtmlText('')
      }
    }

    return {
      formRef,
      formState,
      rules,
      editorComponentRef,
      ...toRefs(state),
      onSubmit,
      resetForm,
      onImageChange,
      setVisible,
    }
  },
})
